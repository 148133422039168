const sidebar = {
  'Home':
  {
    'icon': 'fa-bars',
    'description': '<h2>Notfallkarte</h2><p>Die Punkte auf der Karte können als <a href="table.html" target="_blank">Tabelle</a> angezeigt, heruntergeladen und gedruckt werden.</p><p>Die ganze Seite kann als zip heruntergeladen werden (siehe unten) und auf einem lokalen Webserver (<a href="https://www.maketecheasier.com/setup-local-web-server-all-platforms/" target="_blank">How to Set Up a Local Web Server</a>) betrieben werden. Mit Firefox genügt das lokale entpacken und ein Doppelklick auf die index.html. Die lokale Basiskarte muss augrund der Grösse separat heruntergeladen werden und im Verzeichnis www (als osm-lokal) entpackt werden.<p><ul><li>2022-09-22: Strom-Infos (Links und Layer) ergänzt.</li><li>2022-09-02: Quellen und Basiskarte Swisstopo-Sat hinzugefügt.</li><li>2022-08-23: Grösseres Bild für Naturgefahren Warnkarte.</li><li>2022-08-18: Menüpunkt Weltraumwetter ergänzt.</li></li><li>2022-08-17: Ukaine-Flagge des Leaflet-Entwicklers entfernt.</li><li>2022-08-14: Menüpunkt Wasser ergänzt.</li><li>2022-08-14: Brunnen nach Druckzone aufgeteilt.</li><li>2022-12-15: Energiedashboard hinzugefügt</li></ul><p>Diese Webseite verwendet weder Cookies noch Trackers.</p><h3>Naturgefahren Warnkarte</h3><p><a href="https://www.naturgefahren.ch" target="_blank"><img src="https://www.naturgefahren.ch/content/data/situation/current.image/situation_de_map.png" alt="Warnkarte Naturgefahrenportal" /></a><img class="legende" src="img/naturgefahren-legende.png" alt="Warnkarte Naturgefahrenportal Legende"/></p><div id="swissgrid"></div>',
    'sites':
    [
      {
        'name': 'BWL - Versorgungslage',
        'url': 'https://www.bwl.admin.ch/bwl/de/home/themen/versorgungslage.html',
      },
      {
        'name': 'ALERTSWISS',
        'url': 'https://www.alert.swiss/',
      },
      {
        'name': 'Energiedashboard Schweiz',
        'url': 'https://www.energiedashboard.admin.ch/dashboard'
      },
      {
        'name': 'Swissgrid - Netzkennzahlen',
        'url': 'https://www.swissgrid.ch/de/home/operation/grid-data/current-data.html'
      },
      {
        'name': 'Stromausfall-Info Karte',
        'url': 'https://outage.bkw.ch/'
      },
      {
        'name': 'EWZ - Störungen',
        'url': 'https://www.ewz.ch/de/services/stoerungen.html',
      },
      {
        'name': 'Naturgefahrenportal',
        'url': 'https://www.naturgefahren.ch',
      },
      {
        'name': 'Geodaten der Stadt Zürich',
        'url': 'https://www.stadt-zuerich.ch/geodaten/'
      },
    ],
    'documents':
    [
      {
        'name': 'BWL - Lagebeurteilung',
        'url': 'https://www.bwl.admin.ch/dam/bwl/de/dokumente/dokumentation/publikationen/lagebeurteilung-wl.pdf.download.pdf/Lagebeurteilung-WL_D.pdf',
      },
      {
        'name': 'Seite als Download',
        'url': 'www.zip'
      },
      {
        'name': 'Basiskarte als Download',
        'url': 'osm-local.zip'
      },
    ],
  },
  'Notfall': 
  {
    'description': '<h2>Notfall Informationen</h2>',
    'icon': 'fa-hospital',
    'sites':
    [
      {
        'name': 'AlertSwiss',
        'url': 'https://www.alert.swiss/'
      },
      {
        'name': 'Der Notfalltreffpunkt',
        'url': 'https://www.notfalltreffpunkt.ch/'
      },
    ],
    'documents':
    [
      {
        'name': 'Notfallversorgung durch öffentliche Apotheken im Kanton Zürich',
        'url': 'pdf/Notfallversorgung.pdf'
      },
    ],
  },
  'Grundversorgung': 
  {
    'description': '<h2>Informationen zur Grundversorgung</h2>',
    'icon': 'fa-wheat-awn',
    'sites':
    [
      {
        'name': 'stadtgmües',
        'url': 'https://www.stadtgmues.ch/'
      },
      {
        'name': 'www.myfarm.ch - Hofläden',
        'url': 'https://www.myfarm.ch/de/hoflaeden'
      },
      {
        'name': 'mundraub.org - essbare Landschaften',
        'url': 'https://mundraub.org'
      },
      {
        'name': 'Kanton Zürich - Notvorrat',
        'url': 'https://www.zh.ch/de/wirtschaft-arbeit/wirtschaftliche-landesversorgung/notvorrat.html'
      },
    ],
    'documents':
    [
      {
        'name': 'Kluger Rat - Notvorrat',
        'url': 'pdf/Notvorrat.pdf'
      },
    ],
  },
  'Wasser': 
  {
    'description': '<h2>Informationen zu Wasser</h2><p>Rund 320 der 1200 Brunnen der Stadt Zürich, die meisten historische Altstadtbrunnen, sowie die über 80 Notwasserbrunnen, werden über ein separates Quellwassernetz von 150 Kilometer Länge gespiesen. Das Wasser stammt zum grössten Teil aus Quellfassungen, welche seit dem 15. Jahrhundert in den Hügeln rund um Zürich erstellt wurden.</p><p>Das separate Quellwassernetz hat eine wichtige Bedeutung für die Notwasserversorgung der Stadt Zürich.</p><p>Die übrigen Brunnen sind am normalen Verteilnetz (Züriwasser) der Wasserversorgung angeschlossen und werden – wie die Haushaltungen – mit einer Mischung aus 70 Prozent Seewasser, 15 Prozent Quellwasser und 15 Prozent Grundwasser beliefert.</p>',
    'icon': 'fa-water',
    'sites':
    [
      {
        'name': 'Quellwasserbrunnen',
        'url': 'https://www.stadt-zuerich.ch/dib/de/index/wasserversorgung/brunnen/Quellwasserbrunnen.html'
      },
      {
        'name': 'Kanton Zürich - Trinkwasser',
        'url': 'https://www.zh.ch/de/gesundheit/lebensmittel/trinkwasser.html'
      },
    ],
    'documents':
    [
      {
        'name': 'Brunnen und Quellen in Zürich',
        'url': 'pdf/Brunnen und Quellen in Zuerich.pdf'
      },
    ],
  },
  'Weltraumwetter': 
  {
    'description': '<h2>Informationen zum Weltraumwetter</h2><h3>Aurora</h3><p><img src="https://services.swpc.noaa.gov/images/aurora-forecast-northern-hemisphere.jpg"/></p><h3>K-index</h3><p><img src="https://services.swpc.noaa.gov/images/planetary-k-index.gif"/></p><h3>Koronale Löcher</h3><p><img src="https://sdo.gsfc.nasa.gov/assets/img/latest/latest_512_0193.jpg"/></p>',
    'icon': 'fa-satellite',
    'sites':
    [
      {
        'name': 'www.spaceweather.com',
        'url': 'https://www.spaceweather.com/',
      },
    ],
  },
};

module.exports = sidebar;

