const geojson = [
  {
    "category": "Historic Routes",
    "name": "Russian Antarctic Expedition",
    "url": "geodata/bellingshausen.json",
    "color": "green",
    "width": 3
  },
  {
    "category": "Historic Routes",
    "name": "Nimrod Expedition",
    "url": "geodata/nimrod.json",
    "color": "black",
    "width": 3
  },
  {
    "category": "Historic Routes",
    "name": "Fram Expedition",
    "url": "geodata/fram.json",
    "color": "grey",
    "width": 3
  },
  {
    "category": "Historic Routes",
    "name": "Terra-Nova Expedition",
    "url": "geodata/terra-nova.json",
    "color": "red",
    "width": 3
  },
  {
    "category": "Historic Routes",
    "name": "Endurance Expedition",
    "url": "geodata/endurance.json",
    "color": "pink",
    "width": 3
  },
  {
    "category": "Misc",
    "name": "Place Names",
    "url": "geodata/placenames.json",
    "type": "text",
  },
  {
    "category": "Misc",
    "name": "Basemap Ocean",
    "url": "geodata/basemap_ocean.json",
    "opacity": "1.0",
    "color": "#a3bdd1",
    "width": 0.1
  },
  {
    "category": "Misc",
    "name": "Basemap Land",
    "url": "geodata/basemap_land.json",
    "opacity": "1.0",
    "color": "#f0f0f0",
    "width": 0.1
  },
  {
    "category": "Misc",
    "name": "Basemap Ice Self",
    "url": "geodata/basemap_ice_shelf.json",
    "opacity": "1.0",
    "color": "#cfe1eb",
    "width": 0.1
  },
  {
    "category": "Misc",
    "name": "Basemap Sub-Antarctic",
    "url": "geodata/basemap_sub_antarctic.json",
    "opacity": "1.0",
    "color": "#7cce84",
    "width": 0.1
  },
  {
    "category": "Claims",
    "name": "Argentinia",
    "url": "geodata/argentine.json",
    "color": "#e15989",
    "width": 0.5
  },
  {
    "category": "Claims",
    "name": "Australia",
    "url": "geodata/australian.json",
    "color": "#987db7",
    "width": 0.5
  },
  {
    "category": "Claims",
    "name": "Peter I Island",
    "url": "geodata/peter_i_island.json",
    "color": "green",
    "width": 0.5
  },
  {
    "category": "Claims",
    "name": "Brazil (unofficial)",
    "url": "geodata/brazil.json",
    "color": "yellow",
    "width": 0.5
  },
  {
    "category": "Claims",
    "name": "United Kingdom",
    "url": "geodata/british.json",
    "color": "#b7484b",
    "width": 0.5
  },
  {
    "category": "Claims",
    "name": "Chile",
    "url": "geodata/chilean.json",
    "color": "#e8718d",
    "width": 0.5
  },
  {
    "category": "Claims",
    "name": "Norway",
    "url": "geodata/norwegian.json",
    "color": "#85b66f",
    "width": 0.5 
  },
  {
    "category": "Claims",
    "name": "New Zealand",
    "url": "geodata/new_zealand.json",
    "color": "#d5b43c",
    "width": 0.5 
  },
  {
    "category": "Claims",
    "name": "France",
    "url": "geodata/french.json",
    "color": "#f3a6b2",
    "width": 0.5
  },
  {
    "category": "Claims",
    "name": "New Swabia (historic)",
    "url": "geodata/new_swabia.json",
    "color": "blue",
    "width": 0.5
  },
  {
    "category": "Mountains of Madness",
    "name": "Mountains of Madness",
    "url": "geodata/mountains_of_madness.json",
    "color": "blue",
    "opacity": 0.2,
    "width": 0.5
  },
  {
    "category": "Mountains of Madness",
    "name": "Ancient City Buffer",
    "url": "geodata/ancient_city.json",
    "color": "green",
    "opacity": 0.2,
    "width": 0.5
  },
]
module.exports = geojson;
