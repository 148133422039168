const constants = {
  // Map Info
  '_mapTitle': 'C-Impfrrei Karte',
  // Map Settings
  '_markercluster': 'on',
  '_initZoom': '',
  '_initLat': '',
  '_initLon': '',
  // Map Controls
  '_mapSearch': 'topright',
  '_mapSearchCircleRadius': '20 miles',
  '_mapSearchZoom': 12,
  '_mapZoom': 'topright',
  '_mapMyLocation': 'topright',
  '_menuOpen': ['Dienstleistung'],
  '_categorySelected': ['Dienstleistung'],
  '_groupSelected': [],
  '_popupDisabled': [],
  '_heatmap': [],
  // Table
  '_tableHeader': false,
  '_tableCategories': ['Dienstleistung'],
  '_tableColumns': ['Kategorie', 'Gruppe', 'Image', 'Name', 'Beschreibung', 'Adresse', 'Kontakt', 'Online'],
};

module.exports = constants;

