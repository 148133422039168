const tilelayers = [
  {
    'type': 'wms',
    'url': 'https://www.add.scar.org/geoserver/add/wms',
    'layers': 'add:antarctic_lakes',
    'category': 'Natural',
    'name': 'Lakes',
    'maxZoom': 5,
    'format': 'image/png',
    'transparent': true,
    'zIndex': 1001,
    'crs': 'EPSG3031'
  },
  {
    'type': 'wms',
    'url': 'https://www.add.scar.org/geoserver/add/wms',
    'layers': 'add:antarctic_graticule',
    'category': 'Misc',
    'name': 'Graticule',
    'maxZoom': 5,
    'format': 'image/png',
    'transparent': true,
    'zIndex': 1001,
    'crs': 'EPSG3031'
  },
  {
    'type': 'wms',
    'url': 'https://www.add.scar.org/geoserver/add/wms',
    'layers': 'add:antarctic_coastline',
    'category': 'Misc',
    'name': 'Coastlines',
    'format': 'image/png',
    'transparent': true,
    'zIndex': 1000,
    'crs': 'EPSG3031'
  },
  {
    'type': 'wms',
    'url': 'https://geoserver.leylines.ch/antarctica/wms?',
    'layers': 'WGM2012_Bouguer_ponc_2min_color',
    'category': 'Misc',
    'name': 'Bouguer gravity anomaly',
    'tileSize': 512,
    'tileMatrixSet': '250m',
    'format': 'image/png',
    'zIndex': 997,
    'crs': 'EPSG3031'
  },
  {
    'type': 'wms',
    'url': 'https://geoserver.leylines.ch/antarctica/wms?',
    'layers': 'WGM2012_Freeair_ponc_2min_color',
    'category': 'Misc',
    'name': 'Free-air gravity anomaly',
    'tileSize': 512,
    'tileMatrixSet': '250m',
    'format': 'image/png',
    'zIndex': 998,
    'crs': 'EPSG3031'
  },
  {
    'type': 'wms',
    'url': 'https://geoserver.leylines.ch/antarctica/wms?',
    'layers': 'WGM2012_Isostatic_ponc_2min_color',
    'category': 'Misc',
    'name': 'Isostatic gravity anomaly',
    'tileSize': 512,
    'tileMatrixSet': '250m',
    'format': 'image/png',
    'zIndex': 999,
    'crs': 'EPSG3031'
  },
]
module.exports = tilelayers;
