const sidebartop = {
  'Home':
  {
    'icon': 'fa-bars',
    'description': '<h2>Antarctica and Beyond</h2><p>A map all around Antarctica</p><p>Inspiration: <a href="https://github.com/Underwaternya/antarctica-stations-map" target="_blank">Antarctica Stations Map</a></p>',
    'sites':
    [
      {
        'name': 'Antarctic Datasets',
        'url': 'https://www.antarcticglaciers.org/antarctica-2/antarctic-datasets/'
      },
      {
        'name': 'Antarctic Digital Database Map Viewer',
        'url': 'https://www.add.scar.org/'
      },
      {
        'name': 'Antarctic Explorer 1.0.3',
        'url': 'https://data.gns.cri.nz/ata_geomap/index.html?map=Antarctic_v2022'
      },
      {
        'name': 'Antarctica - Pictures, Facts, History and Travel',
        'url': 'https://www.coolantarctica.com/index.php'
      },
      {
        'name': 'Scientific Committee on Antarctic Research',
        'url': 'https://www.scar.org/'
      },
      {
        'name': 'British Antarctic Survey',
        'url': 'https://www.bas.ac.uk/'
      },
      {
        'name': 'Quantarctica',
        'url': 'https://www.npolar.no/quantarctica/'
      },
    ],
  },
  'Movies':
  {
    'description': '<h2>Videos about Antarctica</h2>',
    'icon': 'fa-film',
    'sites':
    [
      {
        'name': 'With Byrd at the South Pole (1930)',
        'url': 'https://www.youtube.com/watch?v=VaFJmih3t0E'
      },
      {
        'name': 'The Secret Land (1948)',
        'url': 'https://www.youtube.com/watch?v=E9chz8COYVc'
      },
      {
        'name': 'Antarctica: Alien Secrets Beneath the Ice (2019)',
        'url': 'https://vimeo.com/ondemand/antarctica2019'
      },
      {
        'name': 'The Lake at the Bottom of the World',
        'url': 'https://www.antarcticlakefilm.com/'
      },
      {
        'name': 'Black Goo - Die geheimnisvolle Substanz - STONER frank & frei',
        'url': 'https://www.youtube.com/watch?v=VHtJiM-1fIk'
      },
      {
        'name': 'Geheimnisvolle Antarktis - STONER frank & frei',
        'url': 'https://www.youtube.com/watch?v=bNROpknHa7o'
      },
      {
        'name': 'Politische Anomalien: Antarktis - Freimaurer, Neuschwabenland und die Hohlerde 1/2',
        'url': 'https://www.youtube.com/watch?v=ho2Q0GvO1go'
      },
      {
        'name': 'Politische Anomalien: Antarktis - Freimaurer, Neuschwabenland und die Hohlerde 2/2',
        'url': 'https://www.youtube.com/watch?v=LA4Q0XmfxF4'
      },
    ],
  },
  'Data':
  {
    'description': '<h2>Data-Attribution</h2>',
    'icon': 'fa-database',
    'sites':
    [
      {
        'name': 'Hydrothermal vent',
        'url': 'https://vents-data.interridge.org/'
      },
      {
        'name': 'Geomagnetic poles',
        'url': 'https://www.ngdc.noaa.gov/geomag/GeomagneticPoles.shtml'
      },
      {
        'name': 'Volcanos',
        'url': 'https://volcano.si.edu/volcanolist_holocene.cfm'
      },
      {
        'name': 'WGM2012 Global Model',
        'url': 'https://bgi.obs-mip.fr/data-products/grids-and-models/wgm2012-global-model/'
      },
    ],
  },
};
module.exports = sidebartop;
