const constants = {
  // Map Info
  '_mapTitle': 'Spaziergänge und Anlässe in der Schweiz',
  // Map Settings
  '_markercluster': 'on',
  '_initZoom': '',
  '_initLat': '',
  '_initLon': '',
  // Map Controls
  '_mapRouting': true,
  '_mapSearch': 'topright',
  '_mapSearchCircleRadius': '20 miles',
  '_mapSearchZoom': 12,
  '_mapZoom': 'topright',
  '_mapMyLocation': 'topright',
  '_menuOpen': ['Spaziergang'],
  '_categorySelected': [],
  '_groupSelected': ['Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag', 'Sonntag', 'Dezember 2022', 'Januar 2023'],
  '_popupDisabled': [],
  '_heatmap': [],
  // Table
  '_tableHeader': false,
  '_tableCategories': ['Kundgebung', 'Vortrag'],
  '_tableColumns': ['Kategorie', 'Gruppe', 'Image', 'Datum', 'Name', 'Beschreibung', 'Adresse', 'Kontakt', 'Online'],
};

module.exports = constants;

