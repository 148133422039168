const constants = {
  // Map Info
  '_mapTitle': 'Homeschooling Zürich und Region',
  // Map Settings
  '_markercluster': 'on',
  '_initZoom': '12',
  '_initLat': '47.37195765',
  '_initLon': '8.53789297',
  // Map Controls
  '_mapSearch': 'topright',
  '_mapSearchCircleRadius': '20 miles',
  '_mapSearchZoom': 12,
  '_mapZoom': 'topright',
  '_mapMyLocation': 'topright',
  '_menuOpen': ['Netzwerk'],
  '_categorySelected': [],
  '_groupSelected': ['Freie Schule', 'Kinder', 'Familie'],
};
module.exports = constants;

