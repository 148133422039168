const notfall = {
  "Notrufnummern": [
    {
       "name": "Polizei",
       "phone": "Tel:117",
       "webpage": "Stadtpolizei",
       "url": "https://www.stadt-zuerich.ch/pd/de/index/stadtpolizei_zuerich.html"
    },
    {
       "name": "Feuerwehr",
       "phone": "Tel:118",
       "webpage": "Schutz und Rettung",
       "url": "https://www.stadt-zuerich.ch/pd/de/index/schutz_u_rettung_zuerich/feuerwehr.html"
    },
    {
       "name": "Rettungsdienst",
       "phone": "Tel:144",
       "webpage": "Schutz und Rettung",
       "url": "https://www.stadt-zuerich.ch/pd/de/index/schutz_u_rettung_zuerich/sanitaet.html"
    },
    {
       "name": "REGA",
       "phone": "Tel:1414",
       "webpage": "REGA",
       "url": "https://www.rega.ch/ueber-uns/kontakt"
    },
    {
       "name": "Vergiftungsnotfälle",
       "phone": "Tel:145",
       "webpage": "Tox Info Suisse",
       "url": "http://www.toxinfo.ch/"
    },
    {
       "name": "Vergiftungen (Beratung)",
       "phone": "Tel:+41 44 251 66 66",
       "webpage": "Tox Info Suisse",
       "url": "http://www.toxinfo.ch/"
    },
  ],
  "Spitäler und Ärzte": [
    {
       "name": "Ärztefon: Notarzt und Notfallpsychiater",
       "phone": "Tel:0800 33 66 55",
       "webpage": "Ärztefon",
       "url": "http://www.aerztefon.ch/"
    },
    {
       "name": "Stadtspital Triemli",
       "phone": "Tel:+41 44 416 11 11",
       "webpage": "Stadtspital Triemli",
       "url": "https://www.stadt-zuerich.ch/triemli/de/index/notfallzentrum.html"
    },
    {
       "name": "Stadtspital Waid",
       "phone": "Tel:+41 44 417 11 11",
       "webpage": "Stadtspital Waid",
       "url": "https://www.stadt-zuerich.ch/waid/de/index/fachgebiete/notfallzentrum.html"
    },
    {
       "name": "Kinderspital - Notfallstation",
       "phone": "Tel:+41 44 266 71 11",
       "webpage": "Kinderspital",
       "url": "https://www.kispi.uzh.ch/de/kontakt/Seiten/default.aspx"
    },
    {
       "name": "Universitätsspital Zürich",
       "phone": "Tel:+41 44 255 11 11",
       "webpage": "Universitätsspital",
       "url": "http://www.usz.ch/system/seiten/notfall.aspx"
    },
    {
       "name": "Tierspital Zürich",
       "phone": "Tel:+41 44 635 81 12",
       "webpage": "Tierspital Zürich",
       "url": "https://www.tierspital.uzh.ch/de.html"
    },
  ],
  "Apotheken": [
    {
       "name": "Notfallnummer der Zürcher Apotheken",
       "phone": "Tel:0900 55 35 55",
       "webpage": "Notfalldienst-Apotheken Kanton Zürich",
       "url": "http://www.notfall-apotheken-zh.ch/"
    },
  ],
  "Persönliche Krisen": [
    {
       "name": "Dargebotene Hand",
       "phone": "Tel:143",
       "webpage": "Dargebotene Hand",
       "url": "http://www.143.ch/"
    },
    {
       "name": "Telefon- und SMS-Hilfe für Kinder und Jugendliche",
       "phone": "Tel:147",
       "webpage": "Pro Juventute",
       "url": "http://www.147.ch/"
    },
    {
       "name": "Elternnotruf Zürich",
       "phone": "Tel:+41 44 261 88 66",
       "webpage": "Elternnotruf Zürich",
       "url": "http://www.elternnotruf.ch/"
    },
    {
       "name": "Kriseninterventionszentrum KIZ der psychiatrischen Universitätsklinik",
       "phone": "Tel:+41 44 296 73 10",
       "webpage": "Psychiatrische Krisenintervention",
       "url": "http://www.pukzh.ch/diagnose-behandlung/stationaeres-spezialangebot/krisenintervention/"
    },
    {
       "name": "Schlupfhaus",
       "phone": "Tel:+41 43 268 22 68",
       "webpage": "Schlupfhaus",
       "url": "http://www.schlupfhaus.ch/"
    },
    {
       "name": "Mädchenhaus",
       "phone": "Tel:+41 44 341 49 45",
       "webpage": "Mädchenhaus",
       "url": "http://www.maedchenhaus.ch/"
    },
  ]
}

module.exports = notfall;
