window.notfall = require('./notfall');
window.mode = "notfall";

import {loadMap} from './map.js';

import markerClusterGroup from 'leaflet.markercluster';
import layerSupport from 'leaflet.markercluster.layersupport';
import geocoder from 'leaflet-control-geocoder';
import locate from 'leaflet.locatecontrol';
import Fullscreen from 'leaflet-fullscreen';
import Routing from 'leaflet-routing-machine';

import 'leaflet-defaulticon-compatibility';
import 'leaflet-extra-markers';
import 'leaflet.control.layers.tree';
import 'leaflet-sidebar-v2';

import 'leaflet.heat/dist/leaflet-heat.js';

import 'leaflet/dist/leaflet.css';
import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.webpack.css';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import 'leaflet-control-geocoder/dist/Control.Geocoder.css';
import 'leaflet.locatecontrol/dist/L.Control.Locate.css';
import 'leaflet-fullscreen/dist/leaflet.fullscreen.css';
import 'leaflet-routing-machine/dist/leaflet-routing-machine.css';
import 'leaflet-extra-markers/dist/css/leaflet.extra-markers.min.css';
import 'leaflet.control.layers.tree/L.Control.Layers.Tree.css';
import 'leaflet-sidebar-v2/css/leaflet-sidebar.css';
import './css/style.css';

const queryString = window.location.search;
window.urlParams = new URLSearchParams(queryString);

var map = L.map('map', {
  preferCanvas: false,
  fullscreenControl: {
    pseudoFullscreen: false, // if true, fullscreen to page width and height
    position: 'topright'
  },
  attributionControl: true,
  zoomControl: false,
  scrollWheelZoom: true,
  tap: false
}).setView([41.76,-72.69], 11);

if (!L.Browser.mobile) {
   new L.Control.Zoom({ position: 'topright' }).addTo(map);
}

window.addEventListener("load", loadMap(map, mode));
